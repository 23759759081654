/*==== Theme Colors ====*/

$theme-colors: (
  'dark':            #232323,
  'white':           #FFFFFF,
  'primary':         #F57F2F,
  'secondary':       #FFF6E7,
  'warning':         #D69A00,
  'success':         #225B14,
  'danger':          #D80027,
  'text':            #634618,
);

/*==== Typography ====*/

$headings-font-family: 'Poppins', sans-serif;
$body-font-family: 'Open Sans', sans-serif;

$text-font-size:    15px;
$text-line-height:  1.2em;

$h1-font-size:      60px;
$h1-line-height:    1.2em;

$h2-font-size:      45px;
$h2-line-height:    1.2em;

$h3-font-size:      25px;
$h3-line-height:    1.2em;

$h4-font-size:      22px;
$h4-line-height:    1.2em;

$h5-font-size:      20px;
$h4-line-height:    1.2em;

$h6-font-size:      18px;
$h4-line-height:    1.2em;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Poppins:wght@400;500&display=swap");
/*==== Theme Colors ====*/
/*==== Typography ====*/
/* Mobile Devices */
/* Small Tablets */
/* Tablets and Small Desktops */
/* Large tablets and Desktops */
/* Full HD */
body a {
  text-decoration: none;
}
body a:hover {
  text-decoration: none;
  color: #F57F2F;
}

body p,
body a,
body span,
body ul,
body li,
body div {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #634618;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #634618;
  margin-bottom: 0;
}
body h1 {
  font-size: 60px;
  line-height: 1.2em;
}
@media screen and (max-width: 768px) {
  body h1 {
    font-size: 40px;
  }
}
body h2 {
  font-size: 45px;
  line-height: 1.2em;
}
@media screen and (max-width: 768px) {
  body h2 {
    font-size: 26px;
  }
}
body h3 {
  font-size: 25px;
  line-height: 1.2em;
}
@media screen and (max-width: 768px) {
  body h3 {
    font-size: 22px;
  }
}
body h4 {
  font-size: 22px;
  line-height: 1.2em;
}
@media screen and (max-width: 768px) {
  body h4 {
    font-size: 16px;
  }
}
body h5 {
  font-size: 20px;
}
body h6 {
  font-size: 18px;
}

body .btn {
  font-size: 13px;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 0;
  padding: 15px 35px;
  border: 0;
  font-weight: 700;
}
body .btn--primary {
  background-color: #F57F2F;
  color: #FFFFFF;
}
body .btn--primary:focus, body .btn--primary:hover {
  opacity: 0.8;
  background-color: #F57F2F;
  color: #FFFFFF;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 80px 0;
}
@media screen and (max-width: 992px) {
  .cards {
    grid-template-columns: 1fr;
    margin: 50px 0;
  }
}
.cards .card {
  padding: 30px;
  border: 0;
  border-radius: 0;
  position: relative;
  min-height: 180px;
  overflow: hidden;
}
.cards .card img {
  position: absolute;
  right: 0;
  height: 180px;
}
@media screen and (max-width: 1200px) {
  .cards .card img {
    right: -20px;
  }
}
.cards .card__content {
  z-index: 2;
  width: 50%;
}

.clients {
  margin: 80px auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
@media screen and (max-width: 992px) {
  .clients {
    margin: 50px 0;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .clients {
    grid-template-columns: repeat(2, 1fr);
  }
}
.clients img {
  filter: brightness(0.5);
}
.clients img:hover {
  filter: brightness(1);
}

.form {
  display: flex;
}
.form--subscribe input[type=email] {
  width: 520px;
  min-width: 520px;
}
@media screen and (max-width: 576px) {
  .form--subscribe input[type=email] {
    min-width: 100%;
    width: 100%;
  }
}
.form--subscribe input[type=submit] {
  margin-left: 10px;
}
@media screen and (max-width: 576px) {
  .form--subscribe input[type=submit] {
    margin-top: 15px;
    margin-left: 0;
    width: 100%;
  }
}
.form--subscribe .react-inputs-validation__msg_identifier {
  position: absolute;
}
.form--contact .react-inputs-validation__textbox__wrapper,
.form--contact .react-inputs-validation__textarea__wrapper {
  margin-bottom: 15px;
}
.form__confirmation {
  display: none;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
@media screen and (max-width: 992px) {
  .gallery {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 576px) {
  .gallery {
    grid-template-columns: 1fr;
  }
}
.gallery__item {
  cursor: pointer;
  position: relative;
}
.gallery__item:hover:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 127, 47, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.fslightbox-flex-centered span {
  color: #FFFFFF;
}

.hero {
  background-image: url("../public/img/hero-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 280px;
}
@media screen and (max-width: 576px) {
  .hero {
    height: 150px;
  }
}

.icon-list__content {
  margin-left: 12px;
}
.icon-list__content h3 {
  margin-bottom: 15px;
}
.icon-list__item {
  margin-bottom: 15px;
}

input[type=text],
input[type=email],
textarea {
  border: 1px solid #452d08 !important;
  border-radius: 0;
  background-color: transparent;
  padding: 13px 18px;
  color: #634618;
  outline: none;
  font-size: 13px !important;
  width: 100%;
}
input[type=text]:focus,
input[type=email]:focus,
textarea:focus {
  border: 1px solid #3f2a0d;
}

.main-menu .menu-wrapper {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .main-menu .menu-wrapper {
    text-align: center;
  }
}
.main-menu a.menu-item {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 0 20px;
}
@media screen and (max-width: 768px) {
  .main-menu a.menu-item {
    padding: 0 8px;
  }
}
@media screen and (max-width: 576px) {
  .main-menu a.menu-item {
    display: block;
    margin: 10px 0;
    padding: 0;
  }
}

.products {
  margin: 80px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
@media screen and (max-width: 992px) {
  .products {
    margin: 50px auto;
  }
}
@media screen and (max-width: 768px) {
  .products {
    grid-template-columns: 1fr;
  }
}
.products .product-item img {
  height: 250px;
}

.product-summary {
  margin: 80px auto;
}
.product-summary .product-price {
  font-size: 18px;
}
.product-summary .product-meta {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}

.related-products .products {
  margin: 20px auto 60px;
}

.slider {
  padding: 30px 15px 60px;
  background-color: #FFF6E7;
}
.slider__slides {
  position: relative;
}
.slider__background {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  margin: auto;
}
@media screen and (max-width: 992px) {
  .slider__background {
    position: relative;
    margin-bottom: 40px;
  }
}
.slider .slide-item img {
  height: 400px;
  object-fit: contain;
}
.slider .carousel.carousel-slider .control-arrow.control-next:before {
  border-left: 8px solid #F57F2F;
}
.slider .carousel.carousel-slider .control-arrow.control-prev:before {
  border-right: 8px solid #F57F2F;
}
.slider .carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.social-icons a {
  margin: 0 10px;
}
.social-icons a svg {
  width: 22px;
  height: auto;
}

.testimonials {
  margin: 80px auto;
  background-image: url("../public/img/testimonials-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 15px;
}
@media screen and (max-width: 576px) {
  .testimonials {
    background-color: #FFF6E7;
    background-image: none;
  }
}
.testimonials .testimonial {
  width: 75%;
  margin: auto;
}
@media screen and (max-width: 992px) {
  .testimonials .testimonial {
    width: 100%;
  }
}
.testimonials .testimonial__content {
  font-size: 18px;
}
.testimonials .testimonial__author {
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
}
.testimonials .carousel.carousel-slider {
  overflow: visible;
}
.testimonials .carousel.carousel-slider .control-dots {
  bottom: -50px;
}
.testimonials .carousel.carousel-slider .control-dots .dot {
  background-color: #b3a48c;
  width: 12px;
  height: 12px;
  box-shadow: none;
}
.testimonials .carousel.carousel-slider .control-dots .dot.selected {
  background-color: #412903;
}

.footer {
  padding: 30px 0;
  background-color: #FFF6E7;
}
.footer .main-menu .menu-wrapper {
  text-align: center;
  margin-top: 30px;
}

.copyright-bar {
  border-top: 1px solid rgba(69, 45, 8, 0.2);
  padding: 25px 0 15px;
}

.header {
  padding: 30px 0;
  background-color: #FFF6E7;
}
@media screen and (max-width: 768px) {
  .header .logo {
    text-align: center;
    margin-bottom: 20px;
  }
}


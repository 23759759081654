.main-menu {
  .menu-wrapper {
    text-align: right;

    @include md {
      text-align: center;
    }
  }

  a.menu-item {
    font-family: $headings-font-family;
    font-weight: 500;
    padding: 0 20px;

    @include md {
      padding: 0 8px;
    }

    @include sm {
      display: block;
      margin: 10px 0;
      padding: 0;
    }
  }
}
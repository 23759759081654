.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @include lg {
    grid-template-columns: 1fr 1fr;
  }

  @include sm {
    grid-template-columns: 1fr;
  }

  &__item  {
    cursor: pointer;
    position: relative;

    &:hover {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(245,127,47,.4);
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.fslightbox-flex-centered {
  span {
    color: map-get($theme-colors, 'white');
  }
}
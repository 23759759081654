.slider {
  padding: 30px 15px 60px;
  background-color: map-get($theme-colors, 'secondary');

  &__slides {
    position: relative;
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    margin: auto;

    @include lg {
      position: relative;
      margin-bottom: 40px;
    }
  }

  .slide-item {
    img {
      height: 400px;
      object-fit: contain;
    }
  }

  .carousel.carousel-slider {
    .control-arrow.control-next {
      &:before {
        border-left: 8px solid map-get($theme-colors, 'primary');
      }
    }

    .control-arrow.control-prev {
      &:before {
        border-right: 8px solid map-get($theme-colors, 'primary');
      }
    }

    .control-arrow {
      &:hover {
        background: none;
      }
    }
  }
}
.product-summary {
  margin: 80px auto;

  .product-price {
    font-size: 18px;
  }

  .product-meta {
    @include reset-list();
    margin: 20px 0;
    padding: 20px 0;
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }
}

.related-products {
  .products {
    margin: 20px auto 60px;
  }
}


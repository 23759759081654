body {
  .btn {
    font-size: 13px;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 0;
    padding: 15px 35px;
    border: 0;
    font-weight: 700;

    &--primary {
      background-color: map-get($theme-colors, 'primary');
      color: map-get($theme-colors, 'white');

      &:focus,
      &:hover {
        opacity: 0.8;
        background-color: map-get($theme-colors, 'primary');
        color: map-get($theme-colors, 'white');
      }
    }
  }
}
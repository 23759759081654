.hero {
  background-image: url("../public/img/hero-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 280px;

  @include sm {
    height: 150px;
  }
}
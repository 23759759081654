// Input fields
input[type=text],
input[type=email],
textarea {
  border: 1px solid #452d08!important;
  border-radius: 0;
  background-color: transparent;
  padding: 13px 18px;
  color: map-get($theme-colors, 'text');
  outline: none;
  font-size: 13px!important;
  width: 100%;

  &:focus {
    border: 1px solid #3f2a0d;
  }
}
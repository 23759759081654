.form {
  display: flex;

  &--subscribe {
    input[type="email"] {
      width: 520px;
      min-width: 520px;

      @include sm {
        min-width: 100%;
        width: 100%;
      }
    }

    input[type="submit"] {
      margin-left: 10px;

      @include sm {
        margin-top: 15px;
        margin-left: 0;
        width: 100%;
      }
    }

    .react-inputs-validation__msg_identifier {
      position: absolute;
    }
  }

  &--contact {
    .react-inputs-validation__textbox__wrapper,
    .react-inputs-validation__textarea__wrapper {
      margin-bottom: 15px;
    }
  }

  &__confirmation {
    display: none;
  }
}
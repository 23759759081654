.clients {
  margin: 80px auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  @include lg {
    margin: 50px 0;
    grid-template-columns: repeat(3, 1fr);
  }

  @include sm {
    grid-template-columns: repeat(2, 1fr);
  }

  img {
    filter: brightness(0.5);

    &:hover {
      filter: brightness(1);
    }
  }
}
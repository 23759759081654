.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 80px 0;

  @include lg {
    grid-template-columns: 1fr;
    margin: 50px 0;
  }

  .card {
    padding: 30px;
    border: 0;
    border-radius: 0;
    position: relative;
    min-height: 180px;
    overflow: hidden;

    img {
      position: absolute;
      right: 0;
      height: 180px;

      @include xl {
        right: -20px;
      }
    }

    &__content {
      z-index: 2;
      width: 50%;
    }
  }
}
.header {
  padding: 30px 0;
  background-color: map-get($theme-colors, 'secondary');

  .logo {
    @include md {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.icon-list {
  &__content {
    margin-left: 12px;

    h3 {
      margin-bottom: 15px;
    }
  }

  &__item {
    margin-bottom: 15px;
  }
}
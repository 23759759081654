.testimonials {
  margin: 80px auto;
  background-image: url("../public/img/testimonials-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 15px;

  @include sm {
    background-color: map-get($theme-colors, 'secondary');
    background-image: none;
  }

  .testimonial {
    width: 75%;
    margin: auto;

    @include lg {
      width: 100%;
    }

    &__content {
      font-size: 18px;
    }

    &__author {
      font-size: 20px;
      font-weight: 700;
      margin-top: 15px;
    }
  }

  .carousel.carousel-slider {
    overflow: visible;

    .control-dots {
      bottom: -50px;

      .dot {
        background-color: #b3a48c;
        width: 12px;
        height: 12px;
        box-shadow: none;

        &.selected {
          background-color: #412903;
        }
      }
    }
  }
}
.products {
  margin: 80px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @include lg {
    margin: 50px auto;
  }

  @include md {
    grid-template-columns: 1fr;
  }

  .product-item {
    img {
      height: 250px;
    }
  }
}
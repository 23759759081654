body {
  p,
  a,
  span,
  ul,
  li,
  div {
    font-family: $body-font-family;
    font-size: $text-font-size;
    color: map-get($theme-colors, 'text');
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $headings-font-family;
    font-weight: 500;
    color: map-get($theme-colors, 'text');
    margin-bottom: 0;
  }

  h1 {
    font-size: $h1-font-size;
    line-height: $h1-line-height;

    @include md {
      font-size: 40px;
    }
  }

  h2 {
    font-size: $h2-font-size;
    line-height: $h2-line-height;

    @include md {
      font-size: 26px;
    }
  }

  h3 {
    font-size: $h3-font-size;
    line-height: $h3-line-height;

    @include md {
      font-size: 22px;
    }
  }

  h4 {
    font-size: $h4-font-size;
    line-height: $h4-line-height;

    @include md {
      font-size: 16px;
    }
  }

  h5 {
    font-size: $h5-font-size;
  }

  h6 {
    font-size: $h6-font-size;
  }
}
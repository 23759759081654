.footer {
  padding: 30px 0;
  background-color: map-get($theme-colors, 'secondary');

  .main-menu {
    .menu-wrapper {
      text-align: center;
      margin-top: 30px;
    }
  }
}

.copyright-bar {
  border-top: 1px solid rgba(69, 45, 8, .2);
  padding: 25px 0 15px;
}